import type {InputHTMLAttributes, TextareaHTMLAttributes} from 'react';
import {forwardRef} from 'react';
import {cn} from '@delorand/utils/cn';

export type HColor = 'light' | 'dark';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  touched?: boolean;
  error?: string | string[];
  hColor?: HColor;
}

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  touched?: boolean;
  error?: string | string[];
  hColor?: HColor;
  resize?: boolean;
  rows?: number;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label: labelText,
    touched,
    hColor = 'light',
    required,
    error,
    className,
    children,
    ...rest
  } = props;

  return (
    <div className="w-full">
      <div className="mt-2 mb-1 flex h-5 w-full justify-between">
        <label
          htmlFor={labelText}
          className={cn(
            'ml-1 w-full text-sm font-medium',
            hColor === 'light' ? 'text-custom-neutral' : 'text-neutral-700',
            error && 'control-field-error'
          )}
        >
          <span className="text-ellipsis whitespace-nowrap">{labelText}</span>
          {required && <span className="error">*</span>}
        </label>
        {error && (
          <p className="error pr-2 text-ellipsis whitespace-nowrap">
            {error?.toString()}
          </p>
        )}
      </div>
      <div className="relative">
        <input
          id={labelText}
          ref={ref}
          className={cn(
            'control w-full',
            error && 'control-field-error',
            children ? 'pr-12 pl-3' : 'px-3'
          )}
          {...rest}
          onInput={
            rest.type === 'tel'
              ? event => {
                  const value = (event.target as any).value;
                  (event.target as any).value = value.replace(/[^0-9]/g, '');
                }
              : undefined
          }
        />
        <div className="center-y absolute right-2 flex justify-center">
          {children}
        </div>
      </div>
    </div>
  );
});

Input.displayName = 'Input';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    //TODO switch ibg to inputprops
    const {
      label: labelText,
      touched,
      hColor = 'light',
      error,
      resize,
      className,
      required,
      ...rest
    } = props;

    return (
      <div className="w-full">
        <div className="mb-2 flex h-5 w-full justify-between">
          <label
            id={labelText}
            className={cn(
              'ml-1 w-full text-sm font-medium',
              hColor === 'light' ? 'text-custom-neutral' : 'text-neutral-700'
            )}
          >
            {labelText}
            {required && <span className="text-red-500">*</span>}
          </label>
          {error && <span className="error pr-4">{error?.toString()}</span>}
        </div>
        <textarea
          id={labelText}
          ref={ref}
          className={cn('control w-full px-3', !resize && 'resize-none')}
          {...rest}
        />
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';

type FormSelectProps<T> = {
  value?: T | null;
  label?: string;
  error?: string;
  options: (
    | string
    | number
    | {
        value: string | number;
        option: string;
      }
  )[];
  onValueChange?: (value: T) => void;
  triggerClassName?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  className?: string;
};

export const FormSelect = <T extends string | number>({
  label: labelText,
  error,
  value,
  options,
  onValueChange = () => {},
  triggerClassName,
  disabled,
  placeholder = 'Select Category',
  required = false,
  className,
}: FormSelectProps<T>) => (
  <div className={`${className || 'w-full'}`}>
    {labelText && (
      <div className="flex justify-between">
        <label id={labelText} className={cn('ml-1 w-full text-sm font-medium')}>
          {labelText}
          {required && <span className="ml-2 text-red-500">*</span>}
        </label>

        {error && <p className="error pr-4">{error?.toString()}</p>}
      </div>
    )}
    <select
      autoFocus={false}
      className={cn(
        'control mt-1 w-full',
        triggerClassName || 'bg-black/5 dark:bg-white/10'
      )}
      disabled={disabled}
      id={labelText}
      value={value || ''}
      onChange={e =>
        onValueChange(
          typeof value === 'number'
            ? Number(e.target.value)
            : (e.target.value as any)
        )
      }
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map(x => {
        if (typeof x === 'string')
          return (
            <option key={x} value={x} className="bg-black text-white">
              {x.slice(0, 1).toUpperCase() + x.slice(1)}
            </option>
          );
        if (typeof x === 'number')
          return (
            <option key={x} value={x} className="bg-black text-white">
              {x}
            </option>
          );
        return (
          <option key={x.value} value={x.value} className="bg-black text-white">
            {x.option.slice(0, 1).toUpperCase() + x.option.slice(1)}
          </option>
        );
      })}
    </select>
  </div>
);

export const Select = ({
  label: labelText,
  value,
  options,
  onValueChange = () => {},
  disabled,
  className,
}: {
  value?: string;
  label?: string;
  error?: string;
  options: (string | {value: string; option: string})[];
  onValueChange?: (value: string) => void;
  triggerClassName?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
}) => {
  return (
    <select
      className={`control ${className ?? 'w-full bg-transparent'}`}
      disabled={disabled}
      id={labelText}
      value={value || ''}
      onChange={e => onValueChange(e.target.value)}
    >
      {options.map(x => {
        if (typeof x === 'string')
          return (
            <option key={x} value={x} className="bg-black text-white">
              {(x.slice(0, 1).toUpperCase() + x.slice(1)).split('-').join(' ')}
            </option>
          );
        return (
          <option key={x.value} value={x.value} className="bg-black text-white">
            {x.option.slice(0, 1).toUpperCase() + x.option.slice(1)}
          </option>
        );
      })}
    </select>
  );
};
