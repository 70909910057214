import {useRouter, useSearchParams} from 'next/navigation';
import {loginS} from '@delorand/schema/dto/customer';
import Button from '@delorand/ui/src/button';
import {Input} from '@delorand/ui/src/input';
import {PencilSquareIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {useStore} from 'c/store/store';
import {useForm} from 'react-hook-form';
import {mutate} from 'swr';
import useSWRMutation from 'swr/mutation';
import {SToType} from '../../../../../packages/schema/dto/shared';
import RenderError from '../../components/render-error';
import {client} from '../../utils/api';
import {fetchApi} from '../../utils/misc';

const LoginForm = ({email, reset}: {email: string; reset: () => void}) => {
  const searchParams = useSearchParams();
  const router = useRouter();

  const afterAuth = useStore(state => state.afterAuth);

  const {trigger, isMutating, error} = useSWRMutation(
    ['login'],
    async (_, {arg}: {arg: SToType<typeof loginS>}) =>
      fetchApi(client.auths['sign-in'].$post({json: arg})),
    {
      onSuccess: ({data}) => {
        if (data) {
          if (afterAuth) {
            afterAuth();
          }
          mutate(['userdata']);
          router.replace(`${searchParams.get('callbackUrl') ?? '/'}`);
        }
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<SToType<typeof loginS>>({
    resolver: zodResolver(loginS),
    defaultValues: {email},
  });

  return (
    <form
      onSubmit={handleSubmit(values => trigger(values))}
      className="flex flex-col gap-3"
    >
      <div className="flex gap-4">
        <Input
          disabled
          touched={true}
          error={errors.email?.message?.toString()}
          type="email"
          label="E-mail"
          {...register('email')}
        />
        <button type="button" className="mt-4" onClick={reset}>
          <PencilSquareIcon width={20} />
        </button>
      </div>
      <Input
        placeholder="password"
        touched={true}
        error={errors.password?.message?.toString()}
        label="Password"
        type="password"
        {...register('password')}
        autoFocus
      />

      <button
        type="button"
        onClick={() => {
          router.push('/forgot-password');
        }}
        className="text-custom-white mx-auto mt-5 mb-2 text-sm"
      >
        forgot password?
      </button>

      <RenderError msg={error?.message || undefined} />

      <Button type="submit" isLoading={isMutating}>
        SIGN IN
      </Button>
    </form>
  );
};

export default LoginForm;
