import {z} from 'zod';
import {rawFileS, shippingAddressesS} from './shared';

export const userS = z.object({
  firstName: z.string().min(1, 'Invalid').max(50, 'Name too long'),
  lastName: z.string().max(100).nullable(),
  phoneNumber: z.string().nullable(),
  email: z.string().email({message: 'email required'}),
  preferences: z.object({
    newsletters: z.boolean(),
  }),
  shippingAddresses: shippingAddressesS.array(),
  userName: z
    .string()
    .min(4, 'Must be greater than 4')
    .max(20, 'Username too long'),
});

export const userFormS = userS.extend({image: rawFileS}).omit({
  preferences: true,
  shippingAddresses: true,
});

export const loginS = z.object({
  email: z.string().email().min(1, 'required').max(100, 'invalid'),
  password: z.string().min(6, 'enter a valid password').max(200, 'invalid'),
});

export const signupS = loginS
  .extend({
    confirmPassword: z.string().min(6, 'required').max(200, 'invalid'),
  })
  .superRefine(({password}, ctx) => {
    const containsUppercase = (ch: string) => /[A-Z]/.test(ch);
    const containsLowercase = (ch: string) => /[a-z]/.test(ch);
    const containsSpecialChar = (ch: string) =>
      /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/.test(ch);
    let countOfUpperCase = 0,
      countOfLowerCase = 0,
      countOfNumbers = 0,
      countOfSpecialChar = 0;
    for (let i = 0; i < password.length; i++) {
      let ch = password.charAt(i);
      if (!isNaN(+ch)) countOfNumbers++;
      else if (containsUppercase(ch)) countOfUpperCase++;
      else if (containsLowercase(ch)) countOfLowerCase++;
      else if (containsSpecialChar(ch)) countOfSpecialChar++;
    }
    if (
      countOfLowerCase < 1 ||
      countOfUpperCase < 1 ||
      countOfSpecialChar < 1 ||
      countOfNumbers < 1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Weak Password',
        path: ['password'],
      });
    }
  })
  .refine(({password, confirmPassword}) => password === confirmPassword, {
    message: "passwords don't match",
    path: ['confirmPassword'],
  });
