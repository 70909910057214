'use client';

import {useEffect, useState} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter, useSearchParams} from 'next/navigation';
import Button from '@delorand/ui/src/button';
import {Input} from '@delorand/ui/src/input';
import {GoogleSvg} from '@delorand/ui/src/svg/logo';
import {useStore} from 'c/store/store';
import {client} from 'c/utils/api';
import {useUser} from 'c/utils/hooks';
import {mutate} from 'swr';
import useSWRMutation from 'swr/mutation';
import {emailS} from '../../../../../packages/schema/dto/shared';
import RenderError from '../../components/render-error';
import {fetchApi} from '../../utils/misc';
import LoginForm from './login';
import RegisterForm from './register';

function AuthPage() {
  const searchParams = useSearchParams();
  const router = useRouter();

  const afterAuth = useStore(state => state.afterAuth);

  const [user] = useUser();

  const [email, setEmail] = useState('');

  const {trigger, isMutating, data, error, reset} = useSWRMutation(
    ['auth.email'],
    async (_, {arg}: {arg: string}) =>
      (await fetchApi(client.auths['auth-email'].$post({json: {email: arg}})))
        .data
  );

  const {trigger: oauth, isMutating: isLoading} = useSWRMutation(
    ['login'],
    async () => await fetchApi(client.auths['sign-in-oauth'].$post()),
    {
      onSuccess: ({data}) => {
        if (data) {
          window.location.href = data as string;
        }
      },
    }
  );

  useEffect(() => {
    if (user) {
      if (afterAuth) {
        afterAuth();
      }
      mutate(['userdata']);
      router.replace(`${searchParams.get('callbackUrl') ?? '/'}`);
    }
  }, [user]);

  return (
    <div className="mt-20 flex flex-col gap-4 px-5">
      <Link href={'/'} className="w-fit">
        <Image
          alt="ui/src/svg/logo"
          src={`/delorandlogofullwhite.png`}
          height={27}
          width={100}
          className=""
        />
      </Link>

      {!data?.code ? (
        <div className="mt-14 space-y-5">
          <Input
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Enter your email to continue..."
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            disabled={!emailS.safeParse(email).success}
            isLoading={isMutating}
            onClick={() => trigger(email)}
            className="w-full disabled:bg-white"
          >
            Continue
          </Button>
        </div>
      ) : data.code === 'NO_AUTH' ? (
        <RegisterForm email={data.email} reset={() => reset()} />
      ) : data.code === 'AUTH' ? (
        <LoginForm email={data.email} reset={() => reset()} />
      ) : null}

      <RenderError msg={error?.response?.data?.msg} />

      <Button
        isLoading={isLoading}
        onClick={() => oauth()}
        className="w-full disabled:bg-white"
      >
        <GoogleSvg />
        <span className="text-sm font-medium">Or continue with Google</span>
      </Button>

      <p className="text-[13px] font-medium">
        By Continuing, you agree to our{' '}
        <span>
          <a className="text-link-blue" href="/privacy-policy">
            Privacy & Cokie Policy
          </a>
        </span>{' '}
        and{' '}
        <span>
          <a className="text-link-blue" href="#">
            Terms and Conditions.
          </a>
        </span>
      </p>
    </div>
  );
}

export default AuthPage;
