import {useState} from 'react';
import {useRouter, useSearchParams} from 'next/navigation';
import {signupS} from '@delorand/schema/dto/customer';
import {Input} from '@delorand/ui/src/input';
import {LoadingBlur} from '@delorand/ui/src/loader';
import {
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {useStore} from 'c/store/store';
import {client} from 'c/utils/api';
import {useForm, useWatch} from 'react-hook-form';
import {mutate} from 'swr';
import useSWRMutation from 'swr/mutation';
import {SToType} from '../../../../../packages/schema/dto/shared';
import {fetchApi} from '../../utils/misc';

const RegisterForm = ({email, reset}: {email: string; reset: () => void}) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const afterAuth = useStore(state => state.afterAuth);

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm<SToType<typeof signupS>>({
    resolver: zodResolver(signupS),
    defaultValues: {email},
  });

  const watch = useWatch({control});

  const [visble, setVisible] = useState(false);

  const {trigger, isMutating} = useSWRMutation(
    ['session.signup'],
    async (
      _,
      {
        arg,
      }: {
        arg: Omit<SToType<typeof signupS>, 'confirmPassword'>;
      }
    ) => {
      await fetchApi(client.auths['sign-up'].$post({json: arg}));

      if (!watch.email || !watch.password) return;

      return fetchApi(
        client.auths['sign-in'].$post({
          json: {email: watch.email, password: watch.password},
        })
      );
    },
    {
      onSuccess: data => {
        if (data?.data) {
          if (afterAuth) {
            afterAuth();
          }
          mutate(['userdata']);
          router.replace(`${searchParams.get('callbackUrl') ?? '/'}`);
        }
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit(({email, password}) => trigger({email, password}))}
      className="flex flex-col gap-2"
    >
      {isMutating && <LoadingBlur />}
      {/* <div className=' flex items-center'>
          {(fError || error) && (
            <p className='text-center mx-auto text-sm text-orange-600'>
              {fError?.message.toLowerCase().split('_').join(' ')}
              <span className=''>{error?.response?.data}</span>
            </p>
          )}
        </div> */}
      <div className="flex gap-4">
        <Input
          disabled
          touched={true}
          error={errors.email?.message?.toString()}
          type="email"
          label="E-mail"
          {...register('email')}
        />
        <button type="button" className="mt-4" onClick={reset}>
          <PencilSquareIcon width={20} />
        </button>
      </div>

      <Input
        touched={true}
        error={errors.password?.message?.toString()}
        type={visble ? 'text' : 'password'}
        label="Password"
        {...register('password')}
      >
        <button type="button" onClick={() => setVisible(x => !x)}>
          {visble ? <EyeIcon width={23} /> : <EyeSlashIcon width={23} />}
        </button>
      </Input>

      <Input
        touched={true}
        error={errors.confirmPassword?.message?.toString()}
        type={visble ? 'text' : 'password'}
        label="Confirm Password"
        {...register('confirmPassword')}
      >
        <button type="button" onClick={() => setVisible(x => !x)}>
          {visble ? <EyeIcon width={23} /> : <EyeSlashIcon width={23} />}
        </button>
      </Input>
      <button
        type="submit"
        className="mx-auto mt-3 w-full rounded-[12px] bg-white py-2 text-black drop-shadow-md"
      >
        Register
      </button>
    </form>
  );
};

export default RegisterForm;
